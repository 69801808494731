<template>
  <div class="content col-lg-11 col-12 mx-auto">
    <top-title class="my-3" :iconColor="`color:#5529e5;font-size:22px`" style="font-size: 22px"
               :icon="$route.meta.icon" :title="`${$route.meta.title}`"></top-title>
    <div class=" d-flex content-nav py-lg-5 py-4">
      <div v-for="(item,index) in chooseNav" :key="index"
           :class="index == chooseIndex?'active':''"
           class="col"
           @click="ChooseWeapons(item,index)">
        <div class="col d-flex justify-content-center" >
          <div><img style="width: 100px" :src="item.cover" alt="武器分类"></div>
        </div>
      </div>
    </div>
    <div class="d-md-flex d-block align-items-center content-nav p-lg-2">
      <div class="d-flex  flex-wrap ">
        <div @click="sort" class="cursor-pointer  px-md-3 px-1 py-2 rounded d-flex align-items-center ">
          价格
          <img :style="sequenceTab == 0?'':'transform: rotate(180deg)'"
               style="width: 12px;transition:all .5s" class="ml-2" src="@/assets/image/index/ZS.png" alt="">
        </div>
        <div class="d-flex align-items-center  px-md-3 px-1 py-2  ml-1 ">
          <div >
            区间
          </div>
          <div class="">
            <input v-model="sortS" class="panda-border-80 ml-1 text-white" type="text" style="width: 60px;height: 25px;font-size: 14px">
          </div>
          <div class="mx-1">-</div>
          <div >
            <input v-model="end" class="p-1 panda-border-80 text-white" type="text" style="width: 60px;height: 25px;font-size: 14px">
          </div>
        </div>
        <div  class=" px-md-3 px-1 d-flex align-items-center cursor-pointer panda-btn-bg rounded" @click="sort('search')">
          搜索武器
        </div>
      </div>
      <div class="bg-bg-2 mt-md-0 mt-2 d-flex ml-auto align-items-center border-text rounded">
        <div class="ml-3">
          <input v-model="search" class="text-white px-2 py-2"
                 type="text" :placeholder="`搜索`" @keyup.enter="getluckySearch">
        </div>
        <div @click="getluckySearch" class=" px-3 ml-auto cursor-pointer py-2 pan-main-15 " style="line-height: 1.4">
          搜索
        </div>
      </div>
    </div>
    <div class="content-weapon">
      <div class="flex-wrap mt-3 d-flex row" style="margin: 0 -6px">
        <router-link :to="'/active/lucky/' + item.id"
                     class="col-xl-2 col-lg-2 col-md-3 col-6 px-2 mb-3"
                     v-for="(item,index) in chooseluckyList" :key="index">
          <weapon :res-data="item" :index="index">
          </weapon>
        </router-link>
      </div>
      <div class="d-flex justify-content-center">
        <el-pagination
          v-if="pagination"
          background
          layout="prev, pager, next"
          :total="total"
          :page-size="pageSize"
          :pager-count="5"
          @current-change="currentChange"
          prev-text="上一页"
          next-text="下一页"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
  import weapon from "./common/weapon";
  import $api from '@/api/lucky/index'
  export default {
    name: "luckyIndex",
    data() {
      return {

        chooseIndex:0,
        chooseNav:[],
        chooseluckyList:[],
        total:0,
        pageSize:0,
        pagination:true,
        sequenceTab:0,
        type_id:1,
        pageSearch:1,
        search:'',
        end:'',
        sortS:'',
      }
    },
    components:{
      weapon
    },
    created() {
    },
    mounted() {
      this.init()

    },
    computed:{

    },
    methods:{
      sort(search){
        if(search == 'search'){
          this.getList(this.pageSearch,this.type_id,this.search,this.sortS,this.end,this.sequenceTab)
          return
        }
        if(this.sequenceTab == 0){
          this.sequenceTab = 1
        }else {
          this.sequenceTab = 0
        }
        this.getList(this.pageSearch,this.type_id,this.search,this.sortS,this.end,this.sequenceTab)
      },
      async getList(page = 1,type_id = 0,name = '',start = 0,end = 0,sort = 0){
        $api.getluckyList(page,type_id,name,start,end,sort).then(res => {
          this.chooseluckyList = res.data.data
          this.total = res.data.total
          this.pageSize = res.data.per_page
        })
      },
      async init(){
        await this.getluckyType()
        await this.getluckyList()
      },
      async getluckyType(){
        try {
          let {data,code} = await $api.getluckyType()
          if(code == 500)return
          this.chooseNav = data
        }catch (e) {
          console.log(e)
        }
      },
      async getluckyList(page = 1,type_id = 1){
        try {
          let {data,code,message} = await $api.getluckyList(page,type_id)
          if(code == 500)return
          if(code != 200){
            this.$message.error(message)
          }
          this.total = data.total
          this.pageSize = data.per_page
          this.chooseluckyList = data.data
        }catch (e) {
          console.log(e)
        }
      },
      async ChooseWeapons(item,index){
        document.getElementById('mysound').play()
        this.chooseIndex = index
        this.type_id = item.id
        this.search = ''
        this.pagination = false
        let loading = this.$loading({lock:true,text: '正在加载',  spinner: 'el-icon-loading',background: 'rgba(0, 0, 0, 0.7)'})
        setTimeout(() => {
          this.pagination = true
          this.getluckyList(1,item.id)
          loading.close();
        }, 500);

      },
      async getluckySearch(){
        let loading = this.$loading({lock:true,text:'正在加载',  spinner: 'el-icon-loading',background: 'rgba(0, 0, 0, 0.7)'})
        document.getElementById('mysound').play()
        try {
          let {data,code} =await $api.getluckySearch(this.pageSearch,this.search)
          if(code == 500 || code == 404){
            loading.close();
            return
          }
          setTimeout(() => {
            this.chooseluckyList = data.data
            this.total = data.total
            this.pageSize = data.per_page
            loading.close();
          }, 500);
        }catch (e) {
          loading.close();
          console.log('e')
        }
        loading.close();
      },
      currentChange(val){
        if(this.search != ''){
          this.pageSearch = val
          this.getList(val,this.type_id,this.search,this.sortS,this.end,this.sequenceTab)
        }else {
          // this.getluckyList(val,this.type_id)
          this.getList(val,this.type_id,this.search,this.sortS,this.end,this.sequenceTab)
        }
        window.scrollTo(0,0);
      },
    }
  }
</script>

<style scoped lang="scss">
  .content{
    &-nav{
      overflow-x: scroll;
      background-color:#040811;
      border-bottom: 6px solid #111521;
      &::-webkit-scrollbar{
        display: none;
      }
      & .active{
        position: relative;
        &::before{
          content: "";
          width: 2px;
          height: 2px;
          position: absolute;
          left: 50%;
          top: 50%;
          border-radius: 50%;
          box-shadow: #3c33ed 0px 0px 92px 29px;
          background-color: #3c33ed;
        }
      }
    }
  }
  .panda-border-80{
    border: 1px solid #111521;

  }
  .pan-main-15{
    background-color: var(--main-blue);
  }
</style>
